const template = `
  query {
    me {
      id
      role
      userGroups {
        id
        name
        avatar
        unreadCount
      }
      name
      uniqueName
      email
      phone
      gender
      birthday
      avatar
      accessKey
      firebaseToken
      followingIds
      blockedUserIds
      unreadMyfollowing
      unreadChat
      mapPayRewards
      vendorProductCartCount
      stylist {
        id
        isStudioAdmin
        isVisitorAccount
        studioId
        show
        showCalendar
        stylistMembership {
          isManager
          isAssistant
        }
      }
    }
  }
`

export default async function me() {
  const { me } = await request(template)

  return { user: me, stylist: me?.stylist }
}

import requestCurrent from 'request/hooks/current'
import getMinimumVersion from 'request/hooks/get-minimum-version'
import requestLogout from 'request/auth/logout'
import requestSwitch from 'request/auth/switch-account'
import firebaseInit from 'services/init/firebase'

const state = {
  key: null,
  fromApp: null,
  isAdmin: false,
  isMember: false,
  isManager: false,
  isAssistant: false,
  isVisitorAccount: false,
  loading: true,
  user: null,
  stylist: null,
  version: null,
  checkVersion: false,
  ws: null
}

const scope = {}

let init = false

export default function useCurrent() {
  // eslint-disable-next-line no-unused-vars
  const { data, mutate } = useSWR('hooks/current', uuid)
  const t = useI18n()

  const switchToUser = async userId => {
    await requestSwitch(userId)
    await refreshCurrent()
  }

  const fetchUserInfo = async() => {
    state.loading = true
    const { user, stylist } = await requestCurrent()
    const isAdmin = user?.role === 'admin'
    const isMember = !!(user?.stylist?.stylistMembership)
    const isManager = user?.stylist?.stylistMembership?.isManager

    const isAssistant = user?.stylist?.stylistMembership?.isAssistant
    const isVisitorAccount = !!(user?.stylist?.isVisitorAccount)
    state.isAdmin = isAdmin
    state.loading = false
    state.user = user
    state.stylist = stylist
    state.isMember = isMember
    state.isManager = isManager || isAdmin
    state.isAssistant = isAssistant
    state.isVisitorAccount = isVisitorAccount
    state.key = uuid()
    scope.user = user
    mutate()
  }

  const refreshCurrent = async() => {
    try {
      discountConnect()
      await fetchUserInfo()
      if (scope.user) mkt.login(scope.user)
      connectWebsocket(state, mutate)
      connectFirebase()
    } catch (error) {
      setTimeout(refreshCurrent, 2000)
      return
    }

    if (!state.checkVersion) return
    try {
      checkMinimumVersion()
    } catch (error) {
      console.log('check version fail')
    }
  }
  scope.refreshCurrent = refreshCurrent
  h.refreshCurrent = refreshCurrent

  const reloadPageCallback = () => {
    if (!confirm('您的版本過舊，重新載入最新頁面?')) return
    window.location.reload()
  }

  const setCheckVersion = checkVersion => {
    state.checkVersion = checkVersion
    mutate()
  }

  const checkMinimumVersion = async() => {
    const version = await getMinimumVersion(state.version)
    const shouldReloadPage = state.version && state.version !== version
    if (shouldReloadPage) reloadPageCallback()
    if (state.version) return
    state.version = version
  }

  const logout = async skipConfirm => {
    if (!skipConfirm && !confirm(t('common:confirmLogout'))) return
    await requestLogout()
    await refreshCurrent()
  }

  useEffect(() => {
    if (init) return
    init = true
    state.fromApp = location.search.includes('from_app')
    refreshCurrent()
  }, [])

  return {
    ...state,
    logout,
    refreshCurrent,
    switchToUser,
    setCheckVersion
  }
}

const discountConnect = () => {
  if (store.plugin.firebaseSignIn) store.plugin.firebase.auth().signOut()
  const { ws } = scope
  if (!ws) return
  ws.onclose = () => { }
  ws.close()
}

const connectWebsocket = (state, mutate) => {
  if (!scope.user) return

  const { id, accessKey } = scope.user
  const onOpen = ws => {
    ws.send(JSON.stringify({ id, token: accessKey }))
    $messenger.init()
    state.ws = ws
    mutate()
  }
  const onMessage = e => {
    const { type, data } = JSON.parse(e.data)
    if (s[type]) return s[type](data)
  }
  const onClose = () => {
    state.ws = null
    mutate()
    scope.refreshCurrent()
  }
  const ws = createSocket(onOpen, onMessage, onClose)

  scope.ws = ws
  //global.ws = ws
}

const connectFirebase = async() => {
  if (!scope.user) return
  await firebaseInit()
  store.plugin.firebase.auth()
    .signOut()
    .then(() => {
      store.plugin.firebase.auth()
        .signInWithCustomToken(scope.user.firebaseToken)
    })
}
